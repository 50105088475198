<template>
	<div class="d-flex page-wrapper flex-column h-100 justify" :class="{ 'onSidebarOpen': sidebarVisible }">
		<div class="m-auto card-receipt bg-white d-flex flex-column align-items-center" id="cardReceipt">
			<img class="ihs-logo" src="@/assets/logo/ihs-logo-bw.svg" alt="">
			<p class="font-12 text-center px-2 mt-3 mb-1">Jl. Buah Batu No.263A, Turangga, Kec. Lengkong, Kota Bandung,
				Jawa Barat</p>
			<span class="text-center font-12">0821-2023-3942</span>
			<div class="text-center font-12 mt-2">
				Struk Pembelian
			</div>
			<div class="text-center font-12" v-if="order.payments.length">
				{{ order.invoice_number }}
			</div>
			<div class="cashier-container w-100 my-2 py-2 font-12">

				<div class=" d-flex justify-content-between">
					<span>Kasir: {{ cashierName }} </span>
					<span v-if="order.payments.length">{{ order.created_at }} - {{ hourCreatedAt }} </span>
					<span v-else>{{ dateToday() }} </span>

				</div>
				<div class=" d-flex justify-content-between">
					<span>Pelanggan </span>
					<span>{{ order.user.name }}</span>
				</div>
			</div>
			<div class="product font-12 align-self-start align-items-center w-100" v-for="product in order.products"
				:key="product.id">
				<p class="product-name mb-0">{{ product.brand_name }} {{ product.name }}</p>
				<div class="ps-3 d-flex justify-content-between w-100">
					<span> {{ product.quantity }} x {{ formatRupiah(product.price, 'Rp. ') }} </span>
					<span> {{ formatRupiah(product.quantity * product.price, 'Rp.') }}</span>
				</div>
				<div class="ps-3 d-flex justify-content-between w-100" v-if="product.discount">
					<span> Diskon </span>
					<span> - {{ formatRupiah(product.discount * product.quantity, 'Rp.') }}</span>
				</div>
			</div>

			<template v-if="order.payments.length">
				<div class="d-flex justify-content-between align-self-stretch font-12"
					v-if="order.payments[0].total_additional_cost">
					<span>Lainnya</span>
					<span>{{ formatRupiah(order.payments[0].total_additional_cost, 'Rp. ') }}</span>
				</div>


				<div class="d-flex justify-content-between align-self-stretch font-12"
					v-if="order.payments[0].total_point">
					<span>Potongan Poin</span>
					<span>-{{ formatRupiah(order.payments[0].total_point, 'Rp. ') }}</span>
				</div>
			</template>
			<template v-if="order.vouchers.length">
				<div class="d-flex justify-content-between align-self-stretch font-12" v-for="voucher in order.vouchers"
					:key="voucher.id">
					<span>{{ voucher.code }}</span>
					<span v-if="order.payments.length">-{{ formatRupiah(order.payments[0].total_voucher, 'Rp. ')
						}}</span>
					<span v-else>-{{ formatRupiah(voucherAmmount(order.vouchers[0], priceTotal), 'Rp. ') }}</span>

				</div>
			</template>
			<div class="total-container d-flex flex-column pt-2 w-100 mt-3">
				<div class="d-flex justify-content-between">
					<span class="font-18 font-500">Total</span>
					<span class="font-18 font-500" v-if="order.payments.length">{{
						formatRupiah(order.payments[0].total_payment, 'Rp.') }}</span>
					<span class="font-18 font-500" v-else-if="order.vouchers.length">
						{{ formatRupiah(priceTotal - voucherAmmount(order.vouchers[0], priceTotal), 'Rp.') }}
					</span>
					<span class="font-18 font-500" v-else>{{ formatRupiah(priceTotal, 'Rp.') }}</span>

				</div>
			</div>

			<span class="payment-divider w-100 text-center overflow-hidden font-14 mt-1"
				v-if="order.payments.length">Payment</span>
			<div class="payment-detail d-flex flex-column align-self-stretch" v-if="order.payments.length">
				<div v-for="orderItem in order.payments" :key="orderItem.id">

					<div class="d-flex font-12" v-if="orderItem.payment_method_type.toLowerCase() === 'tunai'">
						<span>Tunai</span>
						<span class="ms-auto"
							v-if="order.payments.length === 1 && paymentTunai !== paymentInputInteger">
							{{ formatRupiah(paymentInputInteger, 'Rp.') }}
						</span>
						<span class="ms-auto" v-else>
							{{ formatRupiah(orderItem.total_pay, 'Rp.') }}

						</span>
					</div>
					<div class="d-flex font-12" v-else-if="orderItem.payment_method_type === 'QRIS'">
						<span v-if="orderItem.payment_method_type !== 'Tunai'">
							QRIS - BCA
						</span>
						<span class="ms-auto">
							{{ formatRupiah(orderItem.total_pay, 'Rp. ') }}
						</span>

					</div>
					<div class="d-flex font-12" v-else>
						<span v-if="orderItem.payment_method_type !== 'Tunai'">
							{{ orderItem.payment_method_type }} - {{ orderItem.payment_method_name }}
						</span>
						<span class="ms-auto">
							{{ formatRupiah(orderItem.total_pay, 'Rp. ') }}
						</span>

					</div>
					<div class="d-flex" v-if="orderItem.payment_method_type.toLowerCase() === 'virtual account'">
						<span class="font-12">
							{{ orderItem.payment_method_type }}
						</span>

					</div>
					<!-- <div class="d-flex font-12" v-if="orderItem.payment_method_type.toLowerCase() === 'qris'">
						<span class="font-12">
							{{ orderItem.payment_method_type }}
						</span>
						<span>
							{{ formatRupiah(orderItem.total_pay, 'Rp. ') }}

						</span>

					</div> -->

				</div>
				<div class="d-flex font-12" v-if="Object.keys(paymentTunai).length && order.payments.length === 1">
					<span>Kembali</span>
					<span class="ms-auto">
						{{ formatRupiah(paymentInputInteger - parseInt(paymentTunai.total_payment), 'Rp. ') }}
					</span>
				</div>
			</div>


			<div class="receipt-footer d-flex flex-column align-self-stretch mt-2 py-2">
				<span class="text-center font-12">HARGA SUDAH TERMASUK PAJAK</span>
				<span class="text-center font-12 mb-3">Terima Kasih telah berbelanja di <br> Inti Health Store</span>
				<p class="text-center font-12 mb-0">
					Barang yang sudah dibeli tidak bisa ditukar / dikembalikan
				</p>

			</div>
			<div class="d-flex flex-column w-100 contact">
				<div class="d-flex font-12">
					<span>No Telepon</span>
					<span class="ms-auto">022 7351 4369</span>
				</div>
				<div class="d-flex font-12">
					<span>Whatsapp</span>
					<span class="ms-auto">
						0821-2023-3942
					</span>
				</div>
				<div class="d-flex font-12">
					<span>Instagram</span>
					<span class="ms-auto">
						intihealthstore
					</span>
				</div>
				<div class="d-flex font-12">
					<span>Tiktok</span>
					<span class="ms-auto">
						inti_healthstore
					</span>
				</div>
				<div class="d-flex font-12">
					<span>YouTube</span>
					<span class="ms-auto">
						Inti Health Store
					</span>
				</div>
				<div class="d-flex font-12">
					<span>Website</span>
					<span class="ms-auto">
						inti-healthstore.com
					</span>
				</div>
			</div>

			<div class="end-border"></div>
		</div>

		<div class="footer mt-auto d-flex fixed-bottom bg-white py-3 gap-2">
			<div class="flex-grow-1 pl-3 me-2 d-flex align-items-center">
				<div class=" d-flex align-items-center w-100 mx-3 px-5 return-price-container">
					<button class="button button--outline-grey font-700 d-flex align-items-center gap-3"
						@click="$router.push('/')">
						<img src="@/assets/icons/back-icon.svg" alt="">
						Kembali
					</button>
					<template v-if="Object.keys(paymentTunai).length && order.payments.length === 1">
						<div class="ms-auto me-2 mt-1">Total Kembalian:</div>
						<span class="color-primary font-24 font-500">{{ formatRupiah(paymentInputInteger -
							parseInt(order.payments[0].total_payment), 'Rp. ') }}</span>
					</template>

				</div>
			</div>
			<button class="button button--primary me-4 px-5" v-print="'#cardReceipt'">
				<img src="@/assets/icons/print.svg" alt="print icon" class="me-1">
				Print Struk
			</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import IhsMixin from "@/mixins/ihs.mixins.js";
import print from "vue-print-nb";

export default {
	mixins: [IhsMixin],
	directives: {
		print
	},
	computed: {
		...mapState("home", ["sidebarVisible"]),
		cashierName() {
			return JSON.parse(localStorage.activeCashier).name;
		},
		paymentInput() {
			return localStorage.paymentInput;
		},
		paymentInputInteger() {
			return parseInt(localStorage.paymentInput.replace(/\D/g, ""));
		},
		order() {
			return JSON.parse(localStorage.order);
		},
		priceTotal() {
			return this.order.products.reduce((acc, product) => {
				return acc + product.discounted_price * product.quantity;
			}, 0);
		},
		hourCreatedAt() {
			let b = this.order.verbose_created_at;

			let split = b.split(" ");
			return split[3].slice(0, -3);
		},
		paymentTunai() {
			return this.order.payments.find(
				(payment) => payment.payment_method_type.toLowerCase() === "tunai"
			) || {};
		}
	},
	methods: {
		...mapMutations("home", ["setSidebarVisible"]),
		dateToday() {

			const date = new Date()
			const day = date.getDate().toString().padStart(2, '0');
			const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
			const year = date.getFullYear();
			const hours = date.getHours().toString().padStart(2, '0');
			const minutes = date.getMinutes().toString().padStart(2, '0');

			// Combining components into desired format
			const formattedDate = `${day}/${month}/${year} - ${hours}:${minutes}`;

			return formattedDate;
		},
		voucherAmmount(voucher, subtotal) {
			if (voucher.reduction_type === "percent") {
				let discount = (voucher.value / 100) * subtotal
				return discount
			} else {
				return voucher.value
			}

		}
	},
	created() {
		this.setSidebarVisible(false);
	}
};
</script>

<style lang="scss">
@media print {
	// .ihs-logo {
	// 	height: 70px !important;
	// 	width: auto !important;
	// 	object-fit: contain;
	// 	margin-bottom: 4px;
	// }

	.font-14,
	.font-12 {
		font-size: 12px !important;
	}

	.font-18 {
		font-size: 14px !important;
	}

	.navbar {
		display: none !important;
	}

	.footer {
		display: none !important;
	}

	.card-receipt {
		width: 80mm !important;
		border: 0 !important;
		padding: 10px 0 !important;
		border-radius: 0 !important;
		margin: 0 !important;
	}

	html {
		padding-top: 10x !important;
		padding-bottom: 10px !important;
		margin-bottom: 10px !important;
	}

	.page-wrapper {
		padding: 0 !important;
	}

}
</style>
<style lang="scss" scoped>
.card-receipt {
	padding: 8px 30px 30px 30px;
	width: 315px;
	border: 0.5px solid #cdcdce;
	border-radius: 8px;
}

.cashier-container {
	border-top: 1px dashed black;
	border-bottom: 1px dashed black;
}

.total-container {
	border-top: 1px dashed black;
}

.product-name {
	width: 60%;
}

.receipt-footer {
	border-top: 1px dashed black;
	border-bottom: 2px dashed black;
}

.payment-divider:before,
.payment-divider:after {
	border-top: 1px dashed black;
	content: "";
	display: inline-block;
	height: 1px;
	position: relative;
	vertical-align: middle;
	width: 50%;
}

.payment-divider:before {
	right: 0.5em;
	margin-left: -50%;
}

.payment-divider:after {
	left: 0.5em;
	margin-right: -50%;
}

.page-wrapper {
	background: #f5f5f5;
	transition: margin 400ms ease-in-out;
	overflow-x: hidden;
	position: relative;
	padding-top: 24px;
	padding-bottom: 100px;

	&.onSidebarOpen {
		margin-left: 78px;
	}
}

.return-price-container {
	border-right: 1px solid #E0E0E0;
	padding-right: 20px;
}

.contact {
	border-top: 2px dashed black;
	margin-top: 2px;
	padding-top: 12px;
}

.ihs-logo {
	width: 160px !important;
	object-fit: contain;
	height: auto !important;
	margin-bottom: 8px;
	margin-top: 4px;
	-webkit-filter: grayscale(100%);
	/* Safari 6.0 - 9.0 */
	filter: grayscale(100%);
}

.end-border {
	margin-top: 50px;
	border-top: 1px dashed black;
	width: 100%;

}
</style>